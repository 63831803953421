@import url("https://fonts.googleapis.com/css?family=Raleway");

* {
	font-family: Raleway;
}

html {
	background-color: #dfdfdf;
}
* {
	box-sizing: border-box;
}

body {
	margin: 0;
	padding: 0;
}
.stickyThead {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	z-index: 99;
	background: #fafafa;
}

.navbar {
	display: flex;
	position: relative;
	justify-content: space-between;
	align-items: center;
	background-color: #15818b;
	color: white;
	font-family: Raleway;

	/* font-family: Arial, Helvetica, sans-serif; */
}

.brand-logo {
	padding-top: 0.3125rem;
	padding-bottom: 0.3125rem;
	margin-left: 1.1rem;
	font-size: 1.25rem;
	text-decoration: none;
	white-space: nowrap;
	/* font-size: 35px;
	margin: 0.5rem;
	height: 40px; */
}

.item-adjustment {
	padding-right: 25px !important;
}
.navbar-links {
	height: 100%;
}

.navbar-links ul {
	display: flex;
	margin: 0;
	padding: 0;
}

.navbar-links li {
	list-style: none;
	font-weight: 500;
	font-size: 24px;
}

.navbar-links li a {
	display: block;
	text-decoration: none;
	color: white;
	padding: 1.5rem;
}

.navbar-links li:hover {
	transform: scale(1.15);
	transition: 0.6s ease-in-out;
	font-weight: 800;
	/* background-color: #555; */
}

.toggle-button {
	position: absolute;
	top: 0.75rem;
	right: 1rem;
	display: none;
	flex-direction: column;
	justify-content: space-between;
	width: 30px;
	height: 21px;
}

.toggle-button .bar {
	height: 3px;
	width: 100%;
	background-color: white;
	border-radius: 10px;
}

@media (max-width: 800px) {
	.navbar {
		flex-direction: column;
		align-items: flex-start;
	}

	.toggle-button {
		display: flex;
	}

	.navbar-links {
		display: none;
		width: 100%;
	}

	.navbar-links ul {
		width: 100%;
		flex-direction: column;
	}

	.navbar-links ul li {
		text-align: center;
	}

	.navbar-links ul li a {
		padding: 0.5rem 1rem;
	}

	.navbar-links.active {
		display: flex;
	}
}

.pa-fixed-header {
	background-color: #ffffff !important;
	-webkit-transition: background-color 1s ease-out;
	-moz-transition: background-color 1s ease-out;
	-o-transition: background-color 1s ease-out;
	transition: background-color 1s ease-out;
}

.text-black {
	color: #157293 !important;
}
