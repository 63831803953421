body {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	overflow-x: hidden;

	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.hide-scrollbar {
	overflow: hidden;
}
.hr-style {
	width: 60px;
	background-color: "#7c4dff";
	height: 2px;
}
/* small scrollbar */
::-webkit-scrollbar {
	width: 8px;
}

::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	border-radius: 4px;
}

::-webkit-scrollbar-thumb {
	background-color: #0d636b;
	outline: 1px solid #0d636b;
	border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
	background-color: #15818b;
}

.custom-header {
	background-color: #15818b !important;
	color: white;
}
code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
.container {
	padding: 0;
}

.page-top-background {
	background: linear-gradient(rgba(5, 49, 43, 0.4), rgba(18, 3, 86, 0.4)),
		url("../src/images/bot.jpeg");
	background-size: cover; /* This makes sure the image covers the whole background */
	background-position: center; /* This centers the background image */
	background-repeat: no-repeat;
	height: 100vh;
	margin: 0;
	padding: 0;
}
.services-background {
	background: linear-gradient(
			rgba(218, 226, 225, 0.4),
			rgba(233, 232, 236, 0.9)
		),
		url("../src/images/engine-photo.jpg");
	background-size: cover; /* This makes sure the image covers the whole background */
	background-position: center; /* This centers the background image */
	background-repeat: no-repeat;
}

.services-font {
	font-weight: 500;
	color: #ffffff;
	box-shadow: #15818b px;
}

.container-custom {
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}
.cards-wrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}
.card-custom {
	margin: 10px;
}
.slick-slide img {
	margin: auto;
}
.nav-item:hover {
	border-bottom: 3px solid white;
	/* padding-bottom: 0%; */
	/* background-color: black; */
	/* text-decoration: underline; */
}

.text-overlay {
	position: absolute;
	top: 30%;
	/* left: 50px; */
	width: 100%;
	padding: 2rem;
	padding-left: 8rem;
	padding-bottom: 4rem;
	margin: auto;
	background: linear-gradient(rgba(5, 8, 8, 0.4), rgba(2, 0, 10, 0.4));
}

.text-overlay-center {
	top: 30%;
	position: absolute;
	width: 100%;
	padding: auto;
	margin: auto;
	text-align: center;
	padding: 2rem;
	background: linear-gradient(rgba(4, 1, 17, 0.6), rgba(4, 1, 17, 0.7));
}

.page-height {
	height: 100vh;
}

/* @media (min-width: 768px) {
	For desktop:
	.text-overlay-center {
		top: 35%;
		left: 500px;
		width: 350px;
	}
} */
@media only screen and (min-width: 600px) {
	/* For tablets: */
	.card-text {
		padding-top: 10px;
		padding-bottom: 0;
		font-weight: 700;
		transform-origin: right;
		font-size: 23px;
		text-decoration: #15818b;
		border-radius: 4px;
	}
	.text-overlay {
		width: 100% !important;
	}
	.text-overlay-center {
		top: 15%;
	}
}
@media only screen and (max-width: 768px) {
	/* For mobile phones: */
	.page-height {
		height: 100vh;
	}
	.photo-height {
		height: 100%;
	}
	.padding-for-mobile {
		padding-top: 10px !important;
	}
	.text-overlay-center {
		top: 15%;
		position: absolute;
		width: 100%;
		padding: auto;
		margin: auto;
		text-align: center;
		padding: 2rem;
		background: linear-gradient(rgba(4, 1, 17, 0.6), rgba(4, 1, 17, 0.7));
	}
	.text-overlay {
		top: 25%;
		width: auto;
		margin: auto;
		left: 0;
		text-align: center;
		padding: 2rem;
	}
	.card-text {
		padding-top: 20px;
		padding-bottom: 0;
		font-weight: 700;
		transform-origin: right;
		font-size: 21px;
	}
	.card-text-slidetwo {
		padding-top: 18px !important;
		padding-bottom: 0;
		margin-left: -140px;
		font-weight: 500;
		transform-origin: right;
		padding-right: 20px;
		font-size: 21px !important;
		font-weight: 700;
		color: aliceblue;
	}
}

@media only screen and (min-width: 1300) {
	/* For larger screens */
	.who-we-are-photo-height {
		height: auto;
	}
	.photo-height {
		height: 100vh !important;
	}
	.text-overlay-center {
		top: 30% !important;
		position: absolute;
		width: 100%;
		padding: auto;
		margin: auto;
		text-align: center;
		padding: 2rem;
		background: linear-gradient(rgba(4, 1, 17, 0.6), rgba(4, 1, 17, 0.7));
	}
}
.overlay-title {
	color: azure;
	font-size: xx-large;
}
.second-button {
	padding-left: 30px !important;
}

.card-text {
	padding-top: -5px;
	font-weight: 700;
	color: aliceblue;
	transform-origin: right;
}
.card-text-slidetwo {
	padding-top: -5px;
	margin-left: -145px;
	font-weight: 800;
	font-size: 24px;
	color: aliceblue;
	transform-origin: right;
}
.overlay-title {
	color: azure;
	font-size: 48px;
	margin-bottom: -10px;
}
.btn-style {
	left: 100px;
	display: inline-block;
	background-color: #15818b;
	border-radius: 8px;
	border: 6px white;
	color: #ffffff;
	text-align: center;
	font-size: 22px;
	font-weight: 800;
	padding: 10px;
	width: 200px;
	/* transition: all 0.5s;
	cursor: pointer; */

	/* margin-top: 3px; */
}
.btn-style-slidetwo {
	left: 100px;
	display: inline-block;
	background-color: #15818b;
	border-radius: 8px;
	border: 6px white;
	color: #ffffff;
	text-align: center;
	font-size: 22px;
	font-weight: 800;
	padding: 15px;
	width: 200px;
	/* transition: all 0.5s;
	cursor: pointer; */
	text-align: center;

	/* margin-top: 3px; */
}

.whoWeAreTitle {
	font-weight: 900;
	font-size: xx-large;
}
.pageTitle {
	font-weight: 900;
	font-size: 40px;
	line-height: 1.1em;
	letter-spacing: 0.2px;
	/* font-size: xx-large; */
}
.whoWeAreText {
	color: black;
	font-size: 16px;
}

.dark-background {
	background: linear-gradient(rgba(50, 153, 139, 0.2), rgba(4, 1, 17, 0.2));
}
.brown-background {
	background-image: linear-gradient(to right, #decba4, #3e5151);
	width: 100%;
}
.services-background {
	width: 100%;
	background-color: rgba(
		87,
		64,
		64,
		0.137
	); /* Overlay color with 0.5 opacity (adjust the rgba values and opacity as needed) */
}

.services-text {
	font-weight: 800;
}
.no-dots {
	list-style: none;
}
.whyChooseUs ul {
	margin-left: 5px;
}
.whyChooseUs li {
	color: black;
	font-size: 22px;
	margin: 10px;
}
.whyChooseUs li:hover {
	transform: scale(1.12);
	transition: 0.5s ease-in-out;
}
.whyChooseUsText {
	padding-left: 15px;
	font-weight: 600;
	font-size: 21px;
	padding-top: 45px;
}
.cardGroupText {
	/* padding-left: 15px; */
	font-weight: 500;
	font-size: 18px;
	padding-top: 25px;
	color: black;
}
.ourServiceContainer {
	position: absolute;
	width: 320px;
	padding-bottom: 4rem;
}

/* .services-card{
	overflow: hidden;
  position: relative;
} */
.cardStyle-img-wrapper {
	overflow: hidden;
	position: relative;
}

.cardStyle:hover img {
	transform: scale(1.2, 1.2);
	transition-duration: 550ms;
	transition-timing-function: ease-out;
	height: 250px;
	opacity: 0.7;
}

.cardStyle img {
	transform: scale(1, 1);
	transition-duration: 500ms;
	transition-timing-function: ease-out;
	height: 250px;
}

.member-icons {
	position: absolute;
	bottom: -20%;
	left: 0;
	right: 0;
	margin-right: auto;
	margin-left: auto;
	opacity: 0;
}

.cardStyle-img-wrapper:hover .member-icons {
	/* bottom: 0; */
	opacity: 1;
	top: 40%;
}

.animate {
	transition: all 0.3s ease-in-out;
}
.view-button {
	background-color: #0d636b;
	border-radius: 8px;
	border: 6px white;
	color: #ffffff;
	font-weight: 600;
	text-align: center;
	font-size: 15px;
	/* padding: 10px; */
	width: 140px;
	height: 50px;
}
.view-button:hover {
	background-color: #15818b;
}
.footer-items {
	text-decoration: none;
}
.brand-style {
	color: #15818b;
}
.hr-style {
	width: "60px";
	background-color: #7c4dff;
	height: 2px;
}
.brand-slider {
	width: 100%;
}
.slick-next {
	right: 10px !important;
}
/* Fade-in animation */
@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.animate-fade-in {
	animation: fadeIn 1s ease-in forwards;
}

/* Slide-in from bottom animation */
@keyframes slideIn {
	0% {
		transform: translateY(30px);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}

.animate-slide-in {
	animation: slideIn 1s ease-in forwards;
}

/* Additional custom styles */
.hr-style {
	width: 50px;
	height: 3px;
	background-color: #007bff;
	border: none;
}

img.animate-fade-in {
	animation: fadeIn 1.5s ease-in forwards;
}

/* Common styles for animation */
.animate-move-in-left {
	transform: translateX(-100px);
	opacity: 0;
	animation: moveInLeft 1s ease-out forwards;
}

.animate-move-in-right {
	transform: translateX(100px);
	opacity: 0;
	animation: moveInRight 1s ease-out forwards;
}

@keyframes moveInLeft {
	to {
		transform: translateX(0);
		opacity: 1;
	}
}

@keyframes moveInRight {
	to {
		transform: translateX(0);
		opacity: 1;
	}
}

/* Layout for Our Mission section */
.mission-section .row {
	align-items: center; /* Vertically center text and image */
}

/* Layout for Our Vision section */
.vision-section .row {
	align-items: center;
	flex-direction: row-reverse; /* Image on the left */
}

/* Styling for images */
.section-img {
	width: 100%;
	max-width: 400px; /* Control image size */
	border-radius: 10px; /* Rounded corners */
	box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}
